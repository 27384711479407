<template>
  <div>
    <a-modal
      v-model="visible"
      :title="action === 'Create' ? 'Tambah ' : 'Edit ' + ' Role Permission'"
      :dialogStyle="{ top: '10px' }"
      :destroyOnClose="true"
      :forceRender="true"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-danger" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit()"
        >
          Simpan
        </a-button>
      </template>
      <div class="row">
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Instansi</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-select
            class=""
            style="width: 100%;"
            show-search
            placeholder="Pilih Instansi"
            option-filter-prop="children"
            v-model="input.instansi_id"
            :filter-option="filterOption"
          >
            <a-select-option
              v-for="(data, index) in datainstansi"
              :value="data.id"
              :key="index"
            >
              {{ data.keterangan }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Pegawai</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-select
            class=""
            style="width: 100%;"
            show-search
            placeholder="Pilih Pegawai"
            option-filter-prop="children"
            v-model="input.pegawai_id"
            :filter-option="filterOption"
            @change="pegawaiChange"
          >
            <a-select-option
              v-for="(data, index) in datapegawai"
              :value="data.id"
              :key="index"
            >
              {{ data.nama }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Gaji</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <cleave
            :options="{ numeral: true }"
            class="ant-input"
            :raw="true"
            v-model="input.gaji"
          />
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Potongan</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <cleave
            :options="{ numeral: true }"
            class="ant-input"
            :raw="true"
            v-model="input.potongan"
          />
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Diterima</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <cleave
            :options="{ numeral: true }"
            class="ant-input"
            :raw="true"
            v-model="input.diterima"
          />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import debounce from 'lodash/debounce'

export default {
  components: {
    cleave: Cleave,
  },
  watch: {
    'input.gaji'() {
      this.cleaveInput()
    },
    'input.potongan'() {
      this.cleaveInput()
    },
  },
  data() {
    return {
      action: 'Create',
      editdata: [],
      input: {
        instansi_id: '',
        pegawai_id: '',
        gaji: '',
        potongan: '',
        diterima: '',
      },
      editshiftmode: false,
      datatable: [],
      dataSource: [],
      datainstansi: [],
      datapegawai: [],
      datagolongan: [],
      dataroletype: [],
      visible: false,
      loading: false,
      datenow: '',
    }
  },
  created() {
    this.datenow = lou.datenow()
  },
  methods: {
    moment,
    cleaveInput: debounce(function () {
      this.autoDiterima()
    }, 100),
    pegawaiChange(e) {
      var target = this.datapegawai.findIndex(x => x.id === this.input.pegawai_id)
      var data = this.datapegawai[target]
      console.log('data', data)
      var getGaji = this.datagolongan.findIndex(x => x.id === data.golongan_id)
      if (getGaji !== -1) {
        this.input.gaji = this.datagolongan[getGaji].gaji_pokok
      }
      // this.input.gaji = data.
    },
    autoDiterima() {
      if (
        this.input.gaji !== '' && this.input.potongan !== ''
      ) {
        this.input.diterima = parseInt(this.input.gaji) - parseInt(this.input.potongan)
      }
    },
    inputChange(e) {
      this.autoDiterima()
      // console.log('e', e)
    },
    async submit() {
      this.loading = true
      var fd = this.input
      fd.gaji = fd.gaji === '' || fd.gaji === null ? 0 : parseInt(fd.gaji)
      fd.potongan = fd.potongan === '' || fd.potongan === null ? 0 : parseInt(fd.potongan)
      fd.diterima = fd.diterima === '' || fd.diterima === null ? 0 : parseInt(fd.diterima)
      if (this.action === 'Create') {
        await lou.customUrlPost('gaji/penggajian', fd, true, true)
      } else {
        fd.id = this.editdata.id
        fd.bpr_id = this.editdata.bpr_id
        await lou.customUrlPut('gaji/penggajian', fd, true, true)
      }
      this.loading = false
      this.$parent.getData()
      this.handleCancel()
    },
    async showModal(action, data) {
      var res = await lou.customUrlGet('gaji/instansi', false, true)
      var res1 = await lou.customUrlGet('gaji/pegawai', false, true)
      var res2 = await lou.customUrlGet('gaji/golongan', false, true)
      // var res2 = await lou.customUrlGet('roletype', false, true)
      if (res) {
        this.datainstansi = res.data
      }
      if (res1) {
        this.datapegawai = res1.data
      }
      if (res2) {
        this.datagolongan = res2.data
      }
      // if (res2) {
      //   this.dataroletype = res2.data
      // }
      this.editdata = this.$g.clone(data)
      if (action !== 'Create') {
        // console.log("moment().add(4, 'hours').format('HH:mm')", moment().add(4, 'hours').format('HH:mm'))
        // console.log('this.editdata', this.editdata)
        this.input = this.editdata
        this.editshiftmode = false
      } else {
        this.resetForm()
      }
      this.visible = true
      this.action = action
    },
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    resetForm() {
      this.action = 'Create'
      this.editdata = []
      this.input = {
        instansi_id: '',
        pegawai_id: '',
        gaji: '',
        potongan: '',
        diterima: '',
      }

      this.datatable = []
      this.editshiftmode = false
      this.visible = false
      this.loading = false
      this.datenow = ''
    },
    onSearch(searchText) {
      this.dataSource = !searchText ? [] : ['Pagi', 'Sore', 'Damn']
    },
    handleChange(value) {
      // console.log(`selected ${value}`)
    },
    handleBlur() {
      // console.log('blur')
    },
    handleFocus() {
      // console.log('focus')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    capt(word) {
      return lou.capitalize(word)
    },
  },
}
</script>

<style></style>
